import CTA from "./CTA";

export default function AboutContent(props) {
  const { textSectionOrder, textSection, imageOrder, image } = props;

  return (
    <>
      <div className="container">
        <div className="row g-4 g-xl-5 align-items-center justify-content-between">
          {/* Text Content */}
          <div className="col-12 col-lg-7 col-xl-6">
            <div>
              <h2
                class="h2 fw-bold mb-3"
                style={{
                  fontSize: 36,
                }}
              >
                About Soleil
              </h2>
              <p class="fz-18">
                Soleil was founded by a team of passionate AI researchers,
                blockchain enthusiasts, and industry experts dedicated to
                democratizing access to AI technologies and fostering innovation
                in the field.
                <br />
                <br />
                With a shared vision of creating a more inclusive and
                decentralized future, our team brings together diverse skill
                sets and experiences to drive the development and adoption of
                Soleil.
              </p>
              {/* <a class="btn btn-primary rounded-pill mt-4" href="/#">
                Know More
              </a> */}
            </div>
          </div>

          {/* Image */}
          <div className="col-12 col-lg-5">
            <img className="rounded" src="/new_about.png" alt="" />
          </div>
        </div>
      </div>
      <div className="divider" />

      <CTA
        backgroundColor="primary" // try 'success', 'warning', 'danger', 'info' etc
        text="Invest in Soleil SIL Token Buy on Uniswap Now!"
        buttonText="Buy Token"
        buttonColor="warning"
        buttonURL="/presale"
        buttonIcon=""
      />
      <div className="divider" />
      <div className="container">
        <div className="row g-4 g-xl-5 align-items-center justify-content-between">
          {/* Image */}
          <div className="col-12 col-lg-5">
            <img className="rounded" src="/new_about.png" alt="" />
          </div>
          {/* Text Content */}
          <div className="col-12 col-lg-7 col-xl-6">
            <div>
              <h2
                class="h2 fw-bold mb-3"
                style={{
                  fontSize: 36,
                }}
              >
                Soleil Universe
              </h2>
              <p class="fz-18">
              Soleil is an innovative decentralized platform built on the
Ethereum network that aims to revolutionize the landscape of
artificial intelligence (AI) by providing a seamless integration of
AI technologies and blockchain.
By leveraging the power of smart contracts and decentralized
infrastructure, Soleil facilitates secure, transparent, and efficient
interactions within AI ecosystems. This whitepaper outlines the
architecture, features, and potential applications of Soleil,
illustrating its significance in advancing the adoption and
development of AI technologies
              </p>
              {/* <a class="btn btn-primary rounded-pill mt-4" href="/#">
                Know More
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
