import Home from "./Home";
import Presale from "./Presale";

const routes = [
  { path: "/", component: <Home /> },
  { path: "/home", component: <Home /> },
  { path: "/presale", component: <Presale /> },
];

export default routes;
