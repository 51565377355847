import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  InputBase,
  Stack,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/styles";
import { toast } from "react-toastify";
import web3 from "../web3";
import presaleContract from "../utils/presaleContract";
import { useAccount } from "wagmi";
import tokenContract from "../utils/tokenContract";
import TimerCountDown from "../components/timer/TimerCountdown";
import ProgressBar from "../components/progressbar/ProgressBar";

export default function Presale() {
  const sm = useMediaQuery("(max-width:768px)");
  const { address, isConnected } = useAccount();
  const [open, setOpen] = React.useState(false);
  const [balance, setBalance] = React.useState(0);
  const [totalSupply, setTotalSupply] = React.useState(0);
  const [soldToken, setSoldToken] = React.useState(0);
  const [tokenPerUsd, setTokenPerUsd] = React.useState(0);
  const [tokensAmount, setTokensAmount] = useState("");
  const [tokenValue, setTokenValue] = useState();
  const [bonus, setBonus] = useState();

  useEffect(() => {
    (async () => {
      const supply = await tokenContract.methods.totalSupply().call();
      setTotalSupply(web3.utils.fromWei(supply, "ether"));
      const sold = await presaleContract.methods.soldToken().call();
      setSoldToken(web3.utils.fromWei(sold, "ether"));
      const price = await presaleContract.methods.tokenPerUsd().call();
      setTokenPerUsd(web3.utils.fromWei(price, "ether"));
    })();
  }, []);

  useEffect(() => {
    if (address) {
      (async () => {
        const bal = await presaleContract.methods.users(address).call();
        setBalance(web3.utils.fromWei(bal.token_balance, "ether"));
      })();
    }
  }, [address]);

  const convertToken = async () => {
    try {
      setOpen(true);
      let amount = web3.utils.toWei(tokensAmount, "ether");
      console.log("amount", amount);
      let token = await presaleContract.methods.nativeToToken(amount).call();
      token = web3.utils.fromWei(token, "ether")?.toString();
      console.log("token", token);
      setTokenValue(token);
      setOpen(false);
    } catch (error) {
      toast(error);
    }
  };

  const buyHandler = async () => {
    // setOpen(true);
    if (!isConnected) {
      toast.error("Please Connect Wallet");
    } else if (tokensAmount === "") {
      toast.error("Please Enter Amount");
    } else if (tokensAmount === "0") {
      toast.error("Please Enter Valid Amount");
    } else {
      try {
        setOpen(true);

        var gasPrice = await web3.eth.getGasPrice();
        console.log("gasPrice", gasPrice);

        const tx1 = await presaleContract.methods
          .buyToken(web3.utils.toWei(tokensAmount, "ether"))
          .send({
            from: address,
            value: web3.utils.toWei(tokensAmount, "ether"),
            gas: 200000,
            gasPrice: gasPrice,
          });

        console.log(tx1);
        await Promise.resolve(tx1);
        setTokensAmount("");
        setTokenValue(0);
        setOpen(false);
      } catch (error) {
        console.log("error:", error);
        setOpen(false);
        if (error?.data?.message) {
          toast.error(error?.data?.message);
        } else if (error?.data?.reason) {
          toast.error(error?.data?.reason);
        } else {
          toast.error(error?.message);
        }
        setOpen(false);
      }
    }
  };

  const claimHandler = async () => {
    if (!isConnected) {
      toast.error("Please Connect Wallet");
    } else {
      try {
        setOpen(true);
        var gasPrice = await web3.eth.getGasPrice();
        const tx1 = await presaleContract.methods.claimTokens().send({
          from: address,
          gas: 200000,
          gasPrice: gasPrice,
        });
        await Promise.resolve(tx1);
        setOpen(false);
        toast.success("You have successfully Claimed!");
      } catch (error) {
        setOpen(false);
        if (error?.data?.message) {
          toast.error(error?.data?.message);
        } else if (error?.data?.reason) {
          toast.error(error?.data?.reason);
        } else {
          toast.error(error?.message);
        }
      }
    }
  };

  useEffect(() => {
    async function value() {
      // let dec = await presaleContract.methods.bnbToToken().call();
      // console.log("dec", web3.utils.fromWei(dec, "wei"));
      // settokenDecimals(dec?.toString());
      if (isConnected && tokensAmount) return convertToken();
    }
    value();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokensAmount, isConnected]);
  return (
    <Box
      className="presale"
      sx={{
        // background: `url(${presalebg})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
        backgroundSize: { sm: "100% 100%", xs: "contain" },
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box pb={{ xs: 5, sm: 8, md: 12 }} pt={5}>
        <Container
          maxwidth="lg"
          style={{
            display: "flex",
            // minHeight: "90vh",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "25px",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} my="auto" mx={"auto"}>
              <h2 className="heading">
                We are launching soon on Uniswap
              </h2>
              <br />
              <p className="desc">
                Be part of the future of decentralized AI with Soleil's
                Fairlaunch on Uniswap. Secure your spot to access
                groundbreaking AI technologies powered by blockchain,
                revolutionizing industries and fostering innovation. Don't miss
                this opportunity to be at the forefront of the decentralized AI
                ecosystem. Reserve your tokens now!
                <br />
                <br />
              </p>
              <br />
              <br />

              <a
                href="/whitepaper.pdf"
                target="_blank"
                className="action-btn"
                style={{
                  height: 55,
                  minWidth: 180,
                  fontSize: 18,
                }}
              >
                <span>WHITEPAPER</span>
              </a>
              <a
                href="/"
                target="_blank"
                className="action-btn"
                style={{
                  height: 55,
                  minWidth: 180,
                  fontSize: 18,
                }}
              >
                <span>Buy on Uniswap</span>
              </a>
            </Grid>
            <Grid item xs={12} md={6} mx={"auto"}>
              <Box
                sx={{
                  //   background: `url(${Border})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  backgroundSize: "100% 100%",
                  width: "100%",
                  textAlign: "center",
                  flexDirection: "column",
                  gap: "0px",
                  justifyContent: "flex-start",
                  background: "#0C153B",
                  color: "rgb(194, 212, 248)",
                }}
                className="action-box"
              >
                <Box p={2}>
                  <Box
                    variant="h3"
                    textAlign="center"
                    sx={{
                      fontFamily: "DM Sans",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: { sm: "42px", xs: "28px" },
                      color: "#FFCA2B",
                    }}
                  >
                    Getting Live on Uniswap
                  </Box>
                  <TimerCountDown time={1739515687} />
                  <br />
                  </Box>
                <Box
                  style={{
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
    
                  <button
                    style={{
                      width: "100%",
                      minWidth: sm ? 320 : 400,
                    }}
                    className="action-btn"
                  >
                    <a href="/home">Buy on Uniswap</a>
                  </button>
                </Box>
              </Box>
            </Grid>
          </Grid>
          {/* <Box
            py={10}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                gap: "15px",
                border: "2px solid #085ed6",
                borderRadius: "8px",
                padding: "15px 25px",
                width: 250,
              }}
            >
              <h6 style={{ color: "rgb(132, 128, 174)" }}>Earn Upto</h6>
              <h4>30% APR</h4>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                gap: "15px",
                border: "2px solid #085ed6",
                borderRadius: "8px",
                padding: "15px 25px",
                width: 250,
              }}
            >
              <h6 style={{ color: "rgb(132, 128, 174)" }}>
                Total Value Staked
              </h6>
              <h4>${(bonus?.totalStaked * 0.5)?.toFixed(3)}</h4>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                gap: "15px",
                border: "2px solid #085ed6",
                borderRadius: "8px",
                padding: "15px 25px",
                width: 250,
              }}
            >
              <h6 style={{ color: "rgb(132, 128, 174)" }}>
                Total Tokens Staked
              </h6>
              <h4>{stakers?.totalStaked?.toFixed(3)}</h4>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                gap: "15px",
                border: "2px solid #085ed6",
                borderRadius: "8px",
                padding: "15px 25px",
                width: 250,
              }}
            >
              <h6 style={{ color: "rgb(132, 128, 174)" }}>Unique Stakers</h6>
              <h4>{stakers?.totalStakers}</h4>
            </Box>
          </Box> */}
        </Container>
      </Box>
    </Box>
  );
}
