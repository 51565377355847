import React, { useState, useEffect } from "react";

import { Link, NavLink, useNavigate } from "react-router-dom";
import ScrollTo from "react-scroll-into-view";

import "./styles.scss";
import { ConnectKitButton } from "connectkit";
import { useMediaQuery, useTheme } from "@mui/material";
const Header = () => {
  const navigate = useNavigate();
  const md = useMediaQuery("(max-width:1024px)");
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
    return () => {
      setScroll({});
    };
  }, []);

  const [menuActive, setMenuActive] = useState(null);

  const handleMenuActive = () => {
    setMenuActive(!menuActive);
  };

  const [activeIndex, setActiveIndex] = useState(null);
  const handleDropdown = (index) => {
    setActiveIndex(index);
  };
  let user = localStorage.getItem("connected");
  return (
    <header
      id="header_main"
      className={`header ${scroll ? "is-fixed" : ""}`}
      style={{
        borderBottom: "1px solid #202228",
      }}
    >
      <div className="container big">
        <div className="row">
          <div className="col-12">
            <div className="header__body">
              <div className="header__logo">
                <Link to="/">
                  <img
                    id="site-logo"
                    src="/logo.png"
                    alt=""
                    style={{
                      width: 175,
                      height: 75,
                      objectFit: "contain",
                      // clipPath: "polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)",
                    }}
                  />
                </Link>
              </div>

              <div className="header__right">
                <nav
                  id="main-nav"
                  className={`main-nav ${menuActive ? "active" : ""}`}
                  style={{
                    paddingTop: md && "20px",
                    display: "flex",
                    flexDirection: md && "column",
                    alignItems: "center",
                    gap: md ? "25px" : "25px",
                  }}
                >
                  {md && (
                    <Link to="/">
                      <img
                        id="site-logo"
                        src="/logo.png"
                        alt=""
                        style={{
                          width: 155,
                          height: 65,
                          objectFit: "contain",
                        }}
                      />
                    </Link>
                  )}
                  <a href="/home#" onClick={() => navigate("home#")}>
                    <p
                      style={{
                        fontSize: 18,
                        fontWeight: "bold",
                        cursor: "pointer",
                        color: "#fff",
                      }}
                      className="menu-item"
                    >
                      HOME
                    </p>
                  </a>

                  <a href="/home#about" onClick={() => navigate("home#about")}>
                    <p
                      style={{
                        fontSize: 18,
                        fontWeight: "bold",
                        cursor: "pointer",
                        color: "#fff",
                      }}
                      className="menu-item"
                    >
                      ABOUT
                    </p>
                  </a>
                  <a
                    href="/home#roadmap"
                    onClick={() => navigate("home#roadmap")}
                  >
                    <p
                      style={{
                        fontSize: 18,
                        fontWeight: "bold",
                        cursor: "pointer",
                        color: "#fff",
                      }}
                      className="menu-item"
                    >
                      ROADMAP
                    </p>
                  </a>
                  <a
                    href="/home#features"
                    onClick={() => navigate("home#features")}
                  >
                    <p
                      style={{
                        fontSize: 18,
                        fontWeight: "bold",
                        cursor: "pointer",
                        color: "#fff",
                      }}
                      className="menu-item"
                    >
                      FEATURES
                    </p>
                  </a>

                  <a
                    href="/home#tokenomics"
                    onClick={() => navigate("home#tokenomics")}
                  >
                    <p
                      style={{
                        fontSize: 18,
                        fontWeight: "bold",
                        cursor: "pointer",
                        color: "#fff",
                      }}
                      className="menu-item"
                    >
                      TOKENOMICS
                    </p>
                  </a>
                  <a href="/home#faqs" onClick={() => navigate("home#faqs")}>
                    <p
                      style={{
                        fontSize: 18,
                        fontWeight: "bold",
                        cursor: "pointer",
                        color: "#fff",
                      }}
                      className="menu-item"
                    >
                      FAQ
                    </p>
                  </a>
                  <Link
                    to="/presale"
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    className="menu-item"
                  >
                    Buy Token
                  </Link>

                  <ConnectKitButton.Custom>
                    {({ isConnected, truncatedAddress, show, ensName }) => {
                      return (
                        <button className="action-btn" onClick={show}>
                          <span>
                            {" "}
                            {isConnected
                              ? ensName ?? truncatedAddress
                              : "Connect Wallet"}
                          </span>
                        </button>
                      );
                    }}
                  </ConnectKitButton.Custom>
                </nav>
                {md &&
                  (!menuActive ? (
                    <img
                      src="/menu.svg"
                      alt=""
                      style={{
                        height: 45,
                      }}
                      onClick={() => setMenuActive(!menuActive)}
                    />
                  ) : (
                    <img
                      src="/close.svg"
                      alt=""
                      style={{
                        height: 52,
                      }}
                      onClick={() => setMenuActive(!menuActive)}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
